<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>
        {{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Centre
      </h3>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <div class="w-full">
          <label class="block mb-2">Name</label>
          <vs-input
            v-model="name"
            class="w-full"
            name="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Contact Number</label>
          <vs-input
            v-model="phoneNumber"
            class="w-full"
            name="phoneNumber"
            v-validate="'required|numeric'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('phoneNumber')"
            >{{ errors.first("phoneNumber") }}</span
          >
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Email</label>
          <vs-input
            class="w-full"
            name="email"
            v-validate="'required|email'"
            v-model="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Country</label>
          <v-select
            :options="countryOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="countryFilter"
            @input="onStateChange"
            class="mb-4 md:mb-0"
            data-vv-as="Country"
            name="country"
          />
          <span class="text-danger text-sm" v-show="errors.has('country')">{{
            errors.first("country")
          }}</span>
        </div>
        <div class="mt-5 w-full">
          <label class="block mb-2">State</label>
          <v-select
            :options="stateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="stateFilter"
            @input="onStateChange"
            class="mb-4 md:mb-0"
            data-vv-as="State"
            name="state"
          />
          <span class="text-danger text-sm" v-show="errors.has('state')">{{
            errors.first("state")
          }}</span>
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Street Address</label>
          <!-- <vs-input
            ref="autocomplete"
            v-validate="'required'"
            data-vv-as="Street Address"
            name="streetAddress"
            v-model="streetAddress"
            class="w-full"
            :class="{ hasValue: streetAddress }"
          /> -->

          <input
                type="text"
                placeholder
                ref="autocomplete"
                v-validate="'required'"
                data-vv-as="Street Address"
                name="streetAddress"
                v-model="streetAddress"
                class="vs-inputx vs-input--input normal"
                :class="{ hasValue: streetAddress }"
              />

          <span
            class="text-danger text-sm"
            v-show="errors.has('streetAddress')"
            >{{ errors.first("streetAddress") }}</span
          >
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Town/City</label>
          <vs-input
            label-placeholder="Town/City"
            v-model="town"
            class="w-full"
            name="town"
            v-validate="'required'"
            data-vv-as="Town"
          />
          <span class="text-danger text-sm" v-show="errors.has('town')">{{
            errors.first("town")
          }}</span>
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Postcode</label>
          <vs-input
            label-placeholder="Postcode"
            v-model="postCode"
            class="w-full"
            name="postCode"
            v-validate="'required'"
            data-vv-as="Post Code"
          />
          <span class="text-danger text-sm" v-show="errors.has('Postcode')">{{
            errors.first("Postcode")
          }}</span>
        </div>

        <div class="upload-img mt-5" v-if="!dataImg">
          <input
            type="file"
            class="hidden"
            ref="uploadImgInput"
            @change="updateCurrImg"
            accept="image/*"
          />
          <vs-button @click="$refs.uploadImgInput.click()"
            >Upload Image</vs-button
          >
        </div>
        <template v-if="dataImg">
          <!-- Image Container -->
          <div class="w-74 mx-auto flex items-center justify-center mt-5">
            <img :src="dataImg" alt="img" class="responsive" />
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button @click="$refs.updateImgInput.click()"
              >Update Image</vs-button
            >
            <vs-button class="vs-button-secondary" @click="dataImg = null"
              >Remove Image</vs-button
            >
          </div>
        </template>
        <vs-alert
          v-if="customError != ''"
          icon-pack="feather"
          close-icon="icon-x"
          color="danger"
          >{{ customError }}
        </vs-alert>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
        >Submit</vs-button
      >
      <vs-button
        class="vs-button-secondary"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>
<script
  type="text/javascript"
  async
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD2_dLcglU5iyLUQ9hYiUuvpAyy7iRT3-o&libraries=places"
></script>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";
import { loaded } from "vue2-google-maps";

export default {
  data() {
    return {
      customError: "",
      activeStatus: true,
      dataImg: null,
      dataId: null,
      dataGroupId: null,
      phoneNumber: "",
      name: "",
      email: "",

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      countryOptions: [],
      stateOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      streetAddress: "",
      town: "",
      postCode: "",
      address: {
        displayAddress: "",
        suburb: "",
        state: "",
        street: "",
        streetNumber: "",
        postCode: "",
        location: {
          type: "point",
          coordinates: []
        },
        addressData: []
      }
    };
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    groupId: "",
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      this.dataGroupId = this.groupId;
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.dataId = this.data._id;

        this.phoneNumber = this.data.phoneNumber;
        this.name = this.data.name;
        this.email = this.data.email;
        this.dataImg = this.data.logo;

        let filter = {
          label: this.data.address.country,
          value: this.data.address.country
        };
        if(filter.label==="all"){
          filter.label = "All";
        }
        let selectedCountry = _.find(this.countryOptions, filter);
        this.countryFilter = selectedCountry;
        this.stateOptions = this.countryFilter.state;
        this.stateFilter = {
          label: this.data.address.state,
          value: this.data.address.state
        };

        this.town = this.data.address.suburb;
        this.postCode = this.data.address.postCode;
        this.streetAddress = this.data.address.displayAddress;

        this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
    countryFilter(obj) {
      //this.stateFilter = { label: 'All', value: 'all' };
      this.stateOptions = obj.states;
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return (
        this.phoneNumber &&
          this.name &&
          this.email &&
          // this.countryFilter.value != "All" &&
          // this.stateFilter.value != "all" &&
          this.dataImg &&
        this.streetAddress && this.town && this.postCode
      );
    }
  },
  async mounted() {
    //  console.log( this.google );


    let self = this;


      self.$gmapApiPromiseLazy().then(async () => {

      self.autocomplete = new google.maps.places.Autocomplete(
        self.$refs.autocomplete,
        { types: ["geocode"] }
      );
      // , componentRestrictions: {country: "au"}
      self.autocomplete.addListener("place_changed", () => {
        let place = self.autocomplete.getPlace();
        self.errors.remove("streetAddress");
        let ac = place.address_components;
        self.address.addressData = place.address_components;
        self.address.addressData[0].checkDisplayAddress =
          place.formatted_address;
        self.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        self.address.displayAddress = place.formatted_address;
        self.streetAddress = place.formatted_address;
        self.address.location.coordinates[0] = lon;
        self.address.location.coordinates[1] = lat;
      });
    });






  },
  methods: {
    ...mapActions("center", ["getAllCountries", "updateCenter", "addCenter"]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    initValues() {
      if (this.data._id) return;
      this.dataId = null;
      this.dataImg = null;
      this.phoneNumber = "";
      this.name = "";
      this.email = "";
      this.countryFilter = { label: "All", value: "all" };
      this.stateFilter = { label: "All", value: "all" };

      this.town = "";
      this.postCode = "";
      this.streetAddress = "";

      // this.town = result.address.suburb;
      // this.postCode = result.address.postCode;
      // this.streetAddress = result.address.displayAddress;
    },
    onStateChange() {
      this.errors.clear();
    },    
    async submitData() {
      await this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.countryFilter.value == "all") {
            this.errors.add({
                  field: "country",
                  msg: "Country can't be 'All'"
            });
          return;
          }
          if (this.stateFilter.value == "all") {
            this.errors.add({
                  field: "state",
                  msg: "State can't be 'All'"
            });
          return;
          }
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("groupId", this.dataGroupId);
          data.append("image", this.image);
          data.append("phoneNumber", this.phoneNumber);
          data.append("name", this.name);
          data.append("email", this.email);
          data.append("state", this.stateFilter.value);
          data.append("country", this.countryFilter.value);
          data.append("fullName", "");

          data.append("displayAddress", this.streetAddress);
          let sA = this.streetAddress.split(",");
          data.append("streetAddress", sA[0]);
          data.append("town", this.town);
          data.append("postCode", this.postCode);
          data.append("location", JSON.stringify(this.address.location));

          this.customError = "";

          if (this.dataId !== null) {
            if (this.dataImg == null) {
              this.customError = "Image field is required.";
              return;
            }

            this.$vs.loading();
            await this.updateCenter(data)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Centre Updated successfully.",
                    "success"
                  );
                  this.$emit("refreshCenterData");
                  this.$emit("closeSidebar");
                  this.initValues();
                  this.errors.clear();
                  this.$nextTick(() => {
                    this.$validator.reset();
                  });
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.notify({
                  title: "Failure",
                  text: "Update Center Failed.",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
                this.$vs.loading.close();
                console.error(err);
              });
          } else {
            if (this.dataImg == null) {
              this.customError = "Image field is required.";
              return;
            }

            this.$vs.loading();
            await this.addCenter(data)
              .then(res => {
                if (res.status === 200) {
                  this.$emit("refreshCenterData");
                  this.$emit("closeSidebar");
                  this.initValues();
                  this.errors.clear();
                  this.$nextTick(() => {
                    this.$validator.reset();
                  });
                  this.showMessage(
                    "Success",
                    "Centre added successfully.",
                    "success"
                  );
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                if (err.response.status === 422) {
                  this.errors.add({
                    field: "name",
                    msg: err.response.data.message
                  });
                } else {
                  this.$vs.notify({
                    title: "Failure",
                    text: "Add Centre Failed.",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "danger"
                  });
                }
                this.$vs.loading.close();
              });
          }
        }
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
        this.customError = "";
      }
    }
  },
  created() {
    this.getAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.group.countryOptions;
      })
      .catch(err => {
        console.error(err);
      });
  },
  components: {
    vSelect,
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>

.pac-container {
    z-index: 99999;
}


.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}



</style>
