<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-list">
    <GroupSidebarForm
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
      @updateData="updateData"
    />
    <CenterSidebarForm
      :isSidebarActive="addCenterSidebar"
      @closeSidebar="toggleCenterDataSidebar"
      @refreshCenterData="refreshCenterData"
      :data="centerSidebarData"
      :groupId="groupId"
      @updateData="updateData"
    />

    <vx-card class="mb-base">
      <div class="flex justify-between items-center mb-4">
        <h2 class="mb-5">{{ group.companyName }}</h2>
        <div class="ml-auto">
          <vs-button @click="editGroup(group)" class="mr-5">Edit</vs-button>
          <vs-button
            @click="
              statusToggle(
                group._id,
                group.accountStatus && group.accountStatus === 'Active'
                  ? 'Inactive'
                  : 'Active'
              )
            "
            :color="
              group.accountStatus && group.accountStatus === 'Active'
                ? 'danger'
                : 'success'
            "
          >
            {{
              group.accountStatus && group.accountStatus === "Active"
                ? "Inactive"
                : "Active"
            }}
          </vs-button>
        </div>
      </div>

      <!-- Avatar -->
      <div class="vx-row company-detail-wrap m-0">
        <!-- /Information - Col 2 -->
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-semibold">Company:</label>
            <p>{{ group.companyName }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-semibold">Contact Name:</label>
            <p>{{ group.fullName }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-semibold">Contact Number:</label>
            <p>{{ group.mobileNumber }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-semibold">Email:</label>
            <p>{{ group.email }}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full p-5">
            <label class="font-semibold">Status:</label>
            <vs-button
              :color="
                group.accountStatus && group.accountStatus === 'Active'
                  ? 'success'
                  : 'danger'
              "
            >
              {{
                group.accountStatus && group.accountStatus === "Active"
                  ? "Active"
                  : "Inactive"
              }}
            </vs-button>
          </div>
        </vs-col>
      </div>
    </vx-card>

    <vx-card class="mb-base">
      <h3 class="mb-5">Assessments Subjects</h3>
      <table width="100%">
        <tr>
          <td class="font-semibold">Personhood 360 Standard</td>
          <td class="text-center" style="min-width: 200px; width: 200px;">
            <router-link
              :to="{ name: 'group-assessment-subject', params:{id: $route.params.id } }"
              >View</router-link
            >
          </td>
        </tr>
      </table>
    </vx-card>

    <vx-card>
      <h3 class="mb-5">Learning Centers</h3>
      <div class="vx-row align-items-center mb-6">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">Country</label>
          <v-select
            :options="countryOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="countryFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">Region</label>
          <v-select
            :options="stateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="stateFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="block mb-2">Sort By</label>
          <v-select
            :options="sortByOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="sortByFilter"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <br />
          <vs-button color="primary" type="filled" @click="addNewGroup"
            >Add</vs-button
          >
        </div>
      </div>
      <vs-divider />
      <vs-table max-items="5" pagination :data="centers">
        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Country</vs-th>
          <vs-th>Region</vs-th>
          <vs-th>No. Admin Team</vs-th>
          <vs-th>No. Teacher</vs-th>
          <vs-th>No. Children</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              {{ tr.name }}
            </vs-td>
            <vs-td :data="tr.address.country">
              {{ tr.address.country | capitalize }}
            </vs-td>
            <vs-td :data="tr.address.state">
              {{ tr.address.state | capitalize }}
            </vs-td>
            <vs-td :data="tr._id">
              {{
                tr.centerAdmins.length > 0
                  ? tr.centerAdmins[0].numberOfCenterAdmins
                  : 0
              }}
            </vs-td>
            <vs-td :data="tr._id">
              {{ tr.teachers.length > 0 ? tr.teachers[0].numberOfTeachers : 0 }}
            </vs-td>
            <vs-td :data="tr._id">
              {{ tr.children.length > 0 ? tr.children[0].numberOfChildren : 0 }}
            </vs-td>
            <vs-td :data="tr.isActive">
              {{ tr.isActive ? "Active" : "InActive" }}
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <vs-button
                @click="centerStatusToggle(tr._id, indextr)"
                :color="!tr.isActive ? 'success' : 'danger'"
                style="width:100px; padding:10px!important "
              >
                {{ !tr.isActive ? "Active" : "InActive" }}
              </vs-button>
              <a href="#" class="ml-5" @click.stop="editCenter(tr)">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                />
              </a>
              <!--              <a href="#" @click.stop="deleteCenterData(tr._id)">-->
              <!--                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"/>-->
              <!--              </a>-->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <!--Payment-->
    <vx-card>
      <h3 class="mb-5">Payments</h3>
      <vs-table :data="group.subscriptions">
        <template slot="thead">
          <vs-th>Package Name</vs-th>
          <vs-th>Start Date</vs-th>
          <vs-th>End Date</vs-th>
          <vs-th>Amount</vs-th>
          <vs-th>Status</vs-th>
          <!--          <vs-th>Action</vs-th>-->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.package.name">
              {{ tr.package.name }}
            </vs-td>
            <vs-td
              :data="
                tr.renews.length > 0 ? tr.renews[0].renewedDate : tr.startDate
              "
            >
              {{
                moment(
                  tr.renews.length > 0 ? tr.renews[0].renewedDate : tr.startDate
                ).format("DD/MM/YY")
              }}
            </vs-td>
            <vs-td
              :data="tr.renews.length > 0 ? tr.renews[0].endDate : tr.endDate"
            >
            <span v-if="tr.renews.length > 0">
                {{moment(tr.renews[0].endDate).format("DD/MM/YY")}}
            </span>
            <span v-else-if="tr.endDate">
                {{moment(tr.endDate).format("DD/MM/YY")}}
            </span>
            <span v-else> - </span>
            </vs-td>
            <vs-td :data="tr.price"> ${{ tr.price }}/per child </vs-td>
            <vs-td :data="tr._id">
              {{
                tr.renews.length === 0
                  ? tr.endDate === null
                    ? "Active"
                    : moment(tr.endDate) >= moment()
                    ? "Active"
                    : "Expired"
                  : moment(tr.renews[0].endDate) >= moment()
                  ? "Active"
                  : "Expired"
              }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import GroupSidebarForm from "./GroupSidebarForm.vue";
import CenterSidebarForm from "./CenterSidebarForm.vue";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

export default {
  components: {
    vSelect,
    GroupSidebarForm,
    CenterSidebarForm
  },

  data() {
    return {
      // Data Sidebar
      addNewDataSidebar: false,
      addCenterSidebar: false,
      sidebarData: {},
      centerSidebarData: {},
      groupId: this.$route.params.id,
      group: {},
      admins: [],
      centers: [],

      countryFilter: { label: "All", value: "all" },
      countryOptions: [],
      stateFilter: { label: "All", value: "all" },
      stateOptions: [],
      sortByFilter: { label: "Name", value: "companyName" },
      sortByOptions: [
        { label: "Name", value: "companyName" },
        { label: "Country", value: "country.name" },
        { label: "Region", value: "address.state" }
      ]
    };
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
      this.getCentersOfGroup();
    },
    stateFilter(obj) {
      this.getCentersOfGroup();
    },
    sortByFilter(obj) {
      this.getCentersOfGroup();
    }
  },
  computed: {},
  methods: {
    ...mapActions("group", [
      "getGroupById",
      "getAllCountries",
      "updateGroupStatus"
    ]),
    ...mapActions("center", [
      "deleteCenter",
      "getCenterByGroup",
      "updateCenterStatus"
    ]),
    moment(date) {
      return moment(date);
    },
    refreshCenterData() {
      let $this = this;
      setTimeout(function() {
        $this.getCentersOfGroup();
      }, 1000);
    },
    addNewGroup() {
      this.centerSidebarData = {};
      this.toggleCenterDataSidebar(true);
    },
    editCenter(data) {
      this.centerSidebarData = data;
      this.toggleCenterDataSidebar(true);
    },
    editGroup(data) {
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    async statusToggle(groupId, status) {
      Swal.fire({
        title: "Are you sure you want to update status of this centre?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!"
      }).then(result => {
        if (result.value) {
          this.updateStatus(groupId, status);
        }
      });
    },
    async updateStatus(groupId, status) {
      const data = {
        groupId: groupId,
        accountStatus: status
      };
      await this.updateGroupStatus(data)
        .then(response => {
          this.showMessage(
            "Success",
            "Status updated successfully.",
            "success"
          );
          this.fetchGroupDetail(groupId);
        })
        .catch(error => {
          this.showMessage("Error", error.message, "danger");
          console.log(error);
        });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    async centerStatusToggle(centerId, key) {
      Swal.fire({
        title: "Are you sure you want to update status of this centre?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!"
      }).then(result => {
        if (result.value) {
          this.centerUpdateStatus(centerId, key);
        }
      });
    },
    async centerUpdateStatus(centerId, key) {
      await this.updateCenterStatus(centerId)
        .then(response => {
          this.centers[key].isActive = !this.centers[key].isActive;
          this.showMessage(
            "Success",
            "Status updated successfully.",
            "success"
          );
          // this.getCenterDetails(centerId);
        })
        .catch(error => {
          this.showMessage("Error", error.message, "danger");
          console.log(error);
        });
    },
    deleteCenterData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.acceptDeleteAlert(id);
        }
      });
    },
    acceptDeleteAlert(id) {
      this.$vs.loading();
      this.deleteCenter(id)
        .then(res => {
          if (res.status === 200) {
            this.centers = this.$store.state.center.centersOfGroup;
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Center deleted successfully.",
              "success"
            );
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    toggleCenterDataSidebar(val = false) {
      this.addCenterSidebar = val;
    },
    getCentersOfGroup() {
      let id = this.groupId;
      let centerFilter = {
        id: id,
        country:
          this.countryFilter.value == "all" ? "" : this.countryFilter.value,
        state: this.stateFilter.value == "all" ? "" : this.stateFilter.value,
        sortBy: this.sortByFilter.value
      };

      this.$vs.loading();
      this.getCenterByGroup(centerFilter)
        .then(res => {
          if (res.status === 200) {
            this.centers = this.$store.state.center.centersOfGroup;
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    updateData(data) {
      this.group.companyName = data.companyName;
      this.group.fullName = data.fullName;
      this.group.mobileNumber = data.mobileNumber;
      this.group.email = data.email;
      this.group.countryName = data.address.country;
      this.group.stateName = data.state;
    },
    async fetchGroupDetail(groupId) {
      this.$vs.loading();
      await this.getGroupById(groupId)
        .then(res => {
          if (res.status === 200) {
            this.group = res.data.data;
            this.group.countryName = this.group.address.country;
            this.group.stateName = this.group.address.state;
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    }
  },
  created() {
    let id = this.groupId;

    this.$vs.loading();
    this.fetchGroupDetail(id);

    this.getAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.group.countryOptions;
        this.getCentersOfGroup();
        this.$vs.loading.close();
      })
      .catch(err => {
        this.$vs.loading.close();
      });
  }
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

table tr td {
  padding: 10px 15px !important;
}

.company-detail-wrap {
  border: 1px solid #e7e7e7;

  .w-full {
    label {
      position: relative;
      margin-right: 20px;

      &:after {
        content: ":";
        font-weight: 700;
        position: absolute;
        top: -1px;
        right: 0;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      label {
        width: 250px;
      }
    }
  }
}
</style>
