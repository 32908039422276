<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>
        {{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Group
      </h3>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <div class="w-full">
          <label class="block mb-2">Company Name</label>
          <vs-input
            class="w-full"
            v-model="companyName"
            name="companyName"
            v-validate="'required'"
            data-vv-as="Company Name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('companyName')"
            >{{ errors.first("companyName") }}</span
          >
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Contact Number</label>
          <vs-input
            class="w-full"
            v-model="mobileNumber"
            name="mobileNumber"
            v-validate="'required|numeric'"
            data-vv-as="Contact Number"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('mobileNumber')"
            >{{ errors.first("mobileNumber") }}</span
          >
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Contact Name</label>
          <vs-input
            class="w-full"
            v-model="fullName"
            name="fullName"
            v-validate="'required'"
            data-vv-as="Contact Name"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{
            errors.first("fullName")
          }}</span>
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Email</label>
          <vs-input
            class="w-full"
            name="email"
            v-validate="'required|email'"
            v-model="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>

        <div class="mt-5 w-full">
          <label class="block mb-2">Country</label>
          <v-select
            :options="countryOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="countryFilter"
            @input="onStateChange"
            class="mb-4 md:mb-0"
            name="country"
          />
          <span class="text-danger text-sm" v-show="errors.has('country')">{{
            errors.first("country")
          }}</span>
        </div>
        <div class="mt-5 w-full">
          <label class="block mb-2">State</label>
          <v-select
            :options="stateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="stateFilter"
            @input="onStateChange"
            v-validate="'required'"
            name="state"
            class="mb-4 md:mb-0"
          />

          <span class="text-danger text-sm" v-show="errors.has('state')">{{
            errors.first("state")
          }}</span>
        </div>
        <div class="mt-5 w-full">
          <label class="block mb-2">Account Status</label>
          <vs-switch v-model="accountStatus" />
          {{ isActive ? "Active" : "Inactive" }}
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
        >Submit</vs-button
      >
      <vs-button
        class="vs-button-secondary"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      dataId: null,
      mobileNumber: "",
      accountStatus: true,
      isActive: true,
      fullName: "",
      companyName: "",
      email: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      countryOptions: [],
      stateOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" }
    };
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.dataId = this.data._id;

        this.companyName = this.data.companyName;
        this.mobileNumber = this.data.mobileNumber;
        this.fullName = this.data.fullName;
        this.email = this.data.email;
        this.accountStatus = this.data.accountStatus === "Active";
        if (this.data.address.country === null) {
          this.countryFilter = {
            label: "All",
            value: "all",
            states: [{ label: "All", value: "all" }]
          };
          this.stateOptions = this.countryFilter.states;
          this.stateFilter = { label: "All", value: "all" };
        } else {
          let filter = {
            label: this.data.address.country,
            value: this.data.address.country
          };
          let selectedCountry = _.find(this.countryOptions, filter);
          this.countryFilter = selectedCountry;
          this.stateOptions = this.countryFilter.states;
          this.stateFilter = {
            label: this.data.address.state,
            value: this.data.address.state
          };
        }
        this.initValues();
      }
    },
    countryFilter(obj) {
      // this.stateFilter = {label: 'All', value: ''};
      this.stateOptions = obj.states;
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      console.log(this.countryFilter,"this.countryFilter")
      return (
        !this.errors.any() &&
        // this.countryFilter.value != "all" &&
        // this.stateFilter.value != "all" &&
        this.companyName &&
        this.mobileNumber &&
        this.fullName &&
        this.email
      );
    }
  },
  methods: {
    ...mapActions("group", ["getAllCountries", "updateGroup", "addGroup"]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    initValues() {
      if (this.data._id) return;
      this.dataId = null;

      this.mobileNumber = "";
      this.companyName = "";
      this.fullName = "";
      this.email = "";
      this.activeStatus = true;
      this.countryFilter = { label: "All", value: "all" };
      this.stateFilter = { label: "All", value: "all" };
    },
    onStateChange() {
      this.errors.clear();
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        /* if (result) {
          /!*if (!this.countryFilter.value || this.countryFilter.value === 'all' || !this.stateFilter.value || this.stateFilter.value === 'all') {
            if (!this.countryFilter.value || this.countryFilter.value === 'all') {
              this.errors.add({
                field: 'country',
                msg: 'country is required'
              })
            }*!/
            /!*if (!this.stateFilter.value || this.stateFilter.value === 'all') {
              this.errors.add({
                field: 'state',
                msg: 'state is required'
              })
            }*!/
          } else {*/
            if (this.countryFilter.value == "all") {
            this.errors.add({
                  field: "country",
                  msg: "Country can't be 'All'"
            });
          return;
          }
          if (this.stateFilter.value == "all") {
            this.errors.add({
                  field: "state",
                  msg: "State can't be 'All'"
            });
          return;
          }
        const data = {
          id: this.dataId,
          companyName: this.companyName,
          mobileNumber: this.mobileNumber,
          fullName: this.fullName,
          email: this.email,
          accountStatus: this.accountStatus ? "Active" : "Inactive",
          country: this.countryFilter.value,
          state: this.stateFilter.value
        };
        this.$vs.loading();
        if (this.dataId !== null) {
          this.updateGroup(data)
            .then(res => {
              if (res.status === 200) {
                this.showMessage(
                  "Success",
                  "Group Updated successfully.",
                  "success"
                );
                data.country = this.countryFilter.value;
                this.$emit("updateData", data);
              }
              this.$vs.loading.close();
              this.$emit("closeSidebar");
              this.initValues();
              this.errors.clear();
              this.$nextTick(() => {
                this.$validator.reset();
              });
            })
            .catch(err => {
              this.$vs.loading.close();
              if (err.response.status === 422) {
                if (err.response.data.title == "Company Exist") {
                  this.errors.add({
                    field: "companyName",
                    msg: err.response.data.message
                  });
                } else if (err.response.data.title == "Email Exist") {
                  this.errors.add({
                    field: "email",
                    msg: err.response.data.message
                  });
                }
              } else {
                this.$vs.notify({
                  title: "Failure",
                  text: "Create Group Failed",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
              }
            });
        } else {
          this.addGroup(data)
            .then(res => {
              // if (res.status === 200) {
              this.$vs.loading.close();
              this.$emit("refreshData", true);
              this.showMessage(
                "Success",
                "Group added successfully.",
                "success"
              );
              this.$emit("closeSidebar");
              this.initValues();
              this.errors.clear();
              this.$nextTick(() => {
                this.$validator.reset();
              });
              // }
            })
            .catch(err => {
              this.$vs.loading.close();
              if (err.response.status === 422) {
                if (err.response.data.title == "Company Exist") {
                  this.errors.add({
                    field: "companyName",
                    msg: err.response.data.message
                  });
                } else if (err.response.data.title == "Email Exist") {
                  this.errors.add({
                    field: "email",
                    msg: err.response.data.message
                  });
                }
              } else {
                this.$vs.notify({
                  title: "Failure",
                  text: "Something went wrong.",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
              }
            });
        }
        // }
        // }
      });
    }
  },
  created() {
    this.getAllCountries()
      .then(res => {
        this.countryOptions = this.$store.state.group.countryOptions;
      })
      .catch(err => {
        console.error(err);
      });
  },
  components: {
    vSelect,
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
